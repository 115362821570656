const keyMirror = require("keymirror");

export const AllAppsConstants = keyMirror({
    FETCH_APP_LIST: undefined,
    FETCH_APP_LIST_BY_PAGE: undefined,
    CREATE_APP: undefined,
    FETCH_APP_LIST_BY_ID: undefined,
    FETCH_APP_LIST_BY_ACCOUNT: undefined,
    FETCH_PARAMETER_BY_APP_ACCOUNT: undefined,
    DO_MUTLTI_TASKING: undefined,
    DO_MULTI_TASKING_TRASH: undefined,
    EDIT_APP: undefined,
    EDIT_APP_STATUS: undefined,
    DELETE_APP: undefined,
    DELETE_RESTORE_TRASH_APP: undefined,
    FETCH_APP_FROM_TRASH: undefined,
    FETCH_CHART_DATA: undefined,
    FETCH_LOCATION_DATA: undefined,
    FETCH_ALL_APP_CHART: undefined,
    FETCH_ALL_APP_CHART_FILTER: undefined,
    FETCH_ALL_CHART_APP: undefined,
    FETCH_ALL_CHART_ACCOUNT: undefined,
    CHECK_PACKAGE_NAME: undefined,
});

const Rupee = (props) => {
    const {fill, width, height} = props;
    return (
        <>
            <svg version="1.1" height={height || 24} width={width || 24} id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                 y="0px"
                 viewBox="0 0 1300 1914.5">
                <path fill={fill || "#828282"} d="M865.4,180.9h314.2L1300,0H120.3L0,180.9h204.8c207.4,0,398.9,16,475.4,189.5H120.3L0,551.2h702.8
	c0,0.4,0,0.8,0,1.2c0,130-108,329.4-464.7,329.4l-172.6-0.2l0.2,169l691.2,863.9h307.8l-715.4-893.8
	c294.5-15.9,570.7-180.4,610.5-469.5h219.9L1300,370.4H956.6C942.8,300.7,912.6,233.2,865.4,180.9z"/>
            </svg>

        </>
    )
};
export default Rupee;

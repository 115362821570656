import React from 'react';
import "./LightBlueButton.scss";

const LightBlueButton = props => {
    const {onClick, className, disabled, style, bg} = props;
    return (
        <button type="button"
                style={style}
                className={`btn lightBlueButton ripple-button ${disabled ? "disabled-button" : ""} ${bg ? bg : 'bg-light-cyan-blue'}  ${className || ""}`}
                disabled={disabled}
                onClick={onClick}>{props.children}</button>
    )
};

export default LightBlueButton;
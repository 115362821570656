import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "antd";
import {DeleteFilled, SyncOutlined} from "@ant-design/icons";

import {confirmDelete, errorMessage, successMessage} from "../../utils/common";
import {deleteAccountFromTrash, fetchAccountTrashList} from "../../redux/modules/Dashboard/dashboardActions";
import {CustomModal, DataTable, GreenButton, LightBlueButton, Loading} from "../index";
import {deleteAppsRestore, fetchAppFromTrash} from "../../redux/modules/AllApps/allAppsActions";
import {addRemovePlatformFromTrash, fetchPlatformTrash} from "../../redux/modules/Platform/platformActions";

const TrashListModal = (props) => {
    const {setVisible, visible, isFromPlatform, doMultiTrashActive, getAccountList, isFromApp} = props;
    const dispatch = useDispatch();
    const {dashboard, allApps, platform} = useSelector(state => state);
    const loading = (dashboard?.fetchTrashAccountLoading || dashboard?.deleteRestoreAccountLoading || allApps?.deleteRestoreAppLoading || allApps?.fetchAppFromTrashLoading || platform?.fetchPlatformTrashLoading || platform?.addRestoreTrashPlatformLoading);
    const [trashList, setTrashList] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [removeSelection, setRemoveSelection] = useState(false);

    useEffect(() => {
        getTrashAccountList();
    }, []);

    const HandleRestoreDelete = (id, isRestore = false) => {
        const onSuccess = response => {
            successMessage(`Data ${isRestore ? 'Restore' : 'Deleted'} successfully!`);
            if (isRestore) {
                getAccountList({});
            }
            getTrashAccountList();
        };
        const onFail = err => {
            errorMessage(err);
        };
        const data = {id, isRestore: !isRestore};
        confirmDelete({
            title: `Do you Want to permanent ${isRestore ? 'Restore' : 'Delete'} this ${isFromApp ? 'Application' : isFromPlatform ? 'Platform' : 'Account'}?`,
            content:
                <span>This {isFromApp ? 'Application' : isFromPlatform ? 'Platform' : 'Account'} will be {isRestore ? 'Restored' : 'Deleted'}!</span>,
            onConfirm() {
                if (isFromApp) {
                    dispatch(deleteAppsRestore({data, onSuccess, onFail}));
                } else if (isFromPlatform) {
                    dispatch(addRemovePlatformFromTrash({data, onSuccess, onFail}));
                } else {
                    dispatch(deleteAccountFromTrash({data, onSuccess, onFail}));
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const applicationColumns = [
        {
            title: 'Image',
            dataIndex: '_id',
            width: 100,
            render: (data, value) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="roundImg d-inline-block">
                    <img alt='' height='50px' width='50px' src={value?.image}/>
                </div>
            </div>)
        }, {
            title: 'Application Name', dataIndex: 'applicationName',
        }, {
            title: 'Restore',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <SyncOutlined onClick={() => HandleRestoreDelete(data, true)}/>
                </div>
            </div>)
        }, {
            title: 'Delete',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <DeleteFilled onClick={() => HandleRestoreDelete(data)}/>
                </div>
            </div>)
        },
    ];

    const platformColumns = [
        {
            title: 'Image',
            dataIndex: '_id',
            width: 100,
            render: (data, value) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="roundImg d-inline-block">
                    <img alt='' height='50px' width='50px' src={value?.image}/>
                </div>
            </div>)
        }, {
            title: 'Platform Name', dataIndex: 'platformName',
        }, {
            title: 'Restore',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <SyncOutlined onClick={() => HandleRestoreDelete(data, true)}/>
                </div>
            </div>)
        }, {
            title: 'Delete',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <DeleteFilled onClick={() => HandleRestoreDelete(data)}/>
                </div>
            </div>)
        },
    ];

    const columns = [
        {
            title: 'Image',
            dataIndex: '_id',
            width: 100,
            render: (data, value) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="roundImg d-inline-block">
                    <img alt='' height='50px' width='50px' src={value?.image}/>
                </div>
            </div>)
        }, {
            title: 'Account Name', dataIndex: 'accountName',
        }, {
            title: 'Restore',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <SyncOutlined onClick={() => HandleRestoreDelete(data, true)}/>
                </div>
            </div>)
        }, {
            title: 'Delete',
            dataIndex: '_id',
            width: 100,
            render: (data) => (<div className='d-flex gap-1 align-items-center flex-column'>
                <div className="d-inline-block">
                    <DeleteFilled onClick={() => HandleRestoreDelete(data)}/>
                </div>
            </div>)
        },
    ];

    function getTrashAccountList() {
        const onSuccess = response => {
            const {data} = response;
            setTrashList(data || []);
        };
        const onFail = err => {
        };
        if (isFromApp) {
            dispatch(fetchAppFromTrash({onSuccess, onFail}));
        } else if (isFromPlatform) {
            dispatch(fetchPlatformTrash({onSuccess, onFail}));
        } else {
            dispatch(fetchAccountTrashList({onSuccess, onFail}));
        }
    }


    return (<CustomModal centered
                         className='width-full'
                         onClose={() => setVisible(!visible)}
                         visible={visible}
                         title='Trash List'
    >
        {loading && <Loading position='absolute'/>}
        <div>
            {doMultiTrashActive && <div className='d-flex align-items-center justify-content-end mt-2 gap-2'>
                <GreenButton bg="bg-purple-gradient" className="fs-14 text-white fw-400" disabled={!selectedRowIds?.length} onClick={() => doMultiTrashActive({
                    trashList,
                    setRemoveSelection,
                    setSelectedRowIds,
                    selectedRowIds,
                    getTrashAccountList
                })}>
                    Active
                </GreenButton>
                <LightBlueButton bg="bg-blue-gradient" className="text-white fw-400 fs-14" disabled={!selectedRowIds?.length}
                                 onClick={() => doMultiTrashActive({
                                     trashList,
                                     setRemoveSelection,
                                     setSelectedRowIds,
                                     selectedRowIds,
                                     getTrashAccountList,
                                     isDelete: true
                                 })}>
                    Delete
                </LightBlueButton>
            </div>}
            <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xs={24}>
                    <DataTable
                        rowClassName='data-row'
                        noPagination
                        data={trashList}
                        scroll={{ x: 1000 }}
                        setSelectedRowIds={setSelectedRowIds}
                        removeSelection={removeSelection}
                        rowSelectionByMultiple
                        columns={isFromApp ? applicationColumns : isFromPlatform ? platformColumns : columns}
                    />
                </Col>
            </Row>
        </div>
    </CustomModal>)
};
export default TrashListModal;

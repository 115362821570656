const keyMirror = require("keymirror");

export const AddExtraConstants = keyMirror({
    FETCH_EXTRA_FIELD: undefined,
    CREATE_EXTRA_FIELD: undefined,
    EDIT_EXTRA_FIELD: undefined,
    DELETE_EXTRA_FIELD: undefined,
    FETCH_ALL_EXTRA_FIELD: undefined,
    EDIT_MULTI_EXTRA_FIELD: undefined,
});

import {Avatar as AntAvatar} from 'antd';

const Avtar = (props) => {
    const {imgUrl, userName, size, firstChar, style, className} = props;

    if (imgUrl) {
        return <AntAvatar style={{...style, backgroundColor: "#96befb", color: "white"}} size={size || 'large'}
                          className={className}
                          src={imgUrl}/>
    } else {
        return <AntAvatar style={{...style, backgroundColor: "#96befb", color: "white"}} size={size || 'large'}
                          className={className}>{firstChar ? userName?.charAt() : userName}</AntAvatar>
    }
};
export default Avtar;

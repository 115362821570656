import React, {useState} from 'react'
import "./PhotoUpload.scss"
import UploadImageButton from "../UploadImageButton";

const PhotoUpload = props => {
    const {label, image, onChange, required, uploadImg, className, noImage, height, width, mt} = props;

    const onChangeImage = async (image, isUrl) => {
        await onChange(image, isUrl);
    };

    return (
        <div className={`imageUpload ${className}`}>
            {!noImage && <div className={`d-flex justify-content-center flex-row mb-4 ${mt}`}>
                <div className="roundDiv d-inline-block" style={{height: height, width: width}}>
                    <img src={image} width={width || "75px"} height={height || "75px"}/>
                </div>
            </div>}
            {label && <label className="labelClass fs-13 margin-left-5">{label}{required &&
                <span className="color-red">*</span>}</label>}
            {uploadImg && <UploadImageButton image={image} showUploadIcon userImage onChange={onChangeImage}/>}
        </div>
    )
};

export default PhotoUpload

import React from 'react'
import moment from "moment";
import {Input} from "antd";

import {validateEmail, validatePassword} from "../../utils/common";
import "./TextField.scss"

const TextField = ({
                       name,
                       label,
                       defaultValue,
                       labelWithCmp,
                       labelClass,
                       disabled,
                       style,
                       value,
                       mb0,
                       parentClass,
                       onBlur,
                       onChange,
                       placeholder,
                       type,
                       mt0,
                       error,
                       required,
                       errorMsg,
                       suffix,
                       prefix,
                       autoComplete,
                       passwordValidation
                   }) => {
    const [blur, setBlur] = React.useState(false);

    function errorStatus() {
        return required ? error ? true : (blur && !(type === "email" ? validateEmail(value) : (type === "password" && passwordValidation) ? validatePassword(value) : value)) : false
    }

    const handleOnBlur = () => {
        setBlur(true);
        if (onBlur)
            onBlur();
    };
    return (
        <div className={`textField mt-20 ${mt0} ${parentClass}`} style={style}>
            <div className={`${mb0} form-group`}>
                {label && <label
                    className={`labelClass margin-left-5 ${labelClass}`}>{label}{labelWithCmp && labelWithCmp}{required &&
                    <span className="color-red">*</span>}</label>}
                {type === "password" ? <Input.Password type={type ? type : "text"}
                                                       maxLength={100}
                                                       disabled={disabled}
                                                       autoComplete={autoComplete}
                                                       className={`inputClass ${errorStatus() && 'error'}`}
                                                       name={name}
                                                       style={{height: '40px'}}
                                                       onChange={(e) => {
                                                           e.target.value = e.target.value === " " ? "" : e.target.value;
                                                           if (onChange)
                                                               onChange(e)
                                                       }}
                                                       value={type === "date" ? value ? moment(value).format("yyyy-MM-DD") : "" : value}
                                                       placeholder={placeholder} onBlur={handleOnBlur}/>
                    :
                    <Input type={type ? type : "text"}
                           maxLength={100}
                           disabled={disabled}
                           className={`inputClass ${errorStatus() && 'error'}`}
                           name={name}
                           prefix={prefix}
                           suffix={suffix}
                           autoComplete={autoComplete}
                           defaultValue={defaultValue}
                           onChange={(e) => {
                               e.target.value = e.target.value === " " ? "" : e.target.value;
                               if (onChange)
                                   onChange(e)
                           }} value={type === "date" ? value ? moment(value).format("yyyy-MM-DD") : "" : value}
                           placeholder={placeholder} onBlur={handleOnBlur}/>}
                {errorStatus() && <span
                    className="margin-left-5 error-label">{(type === "email" && value) ? "Enter valid email address" : (type === "password" && passwordValidation && value) ? "Enter Valid Password" : errorMsg || `${name} is required`}</span>}
                {(!!value && type === "email" && !validateEmail(value) && !required) && <span
                    className="margin-left-5 error-label">Enter valid email address</span>}
            </div>
        </div>
    )
};
export default TextField

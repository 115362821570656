const TripleDot = (props) => {
    const {fill, style, className} = props;
    return (
        <svg className={className} version="1.1" height="15" width="15" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             viewBox="0 0 15 15" style={{enableBackground: "new 0 0 15 15", ...style}} fill={fill || 'white'}>
            <path className="st0" d="M2.5,5.8c0.3,0,0.7,0.1,1,0.3C3.8,6.3,4,6.6,4.1,7c0.1,0.4,0.2,0.8,0.1,1.1C4.1,8.5,4,8.9,3.7,9.2
	C3.5,9.4,3.2,9.6,2.8,9.7c-0.3,0.1-0.7,0-1-0.1C1.5,9.4,1.2,9.2,1,8.9C0.9,8.5,0.8,8.2,0.8,7.8c0-0.5,0.2-1,0.5-1.4
	C1.6,6,2,5.8,2.5,5.8z M7.7,5.8c0.3,0,0.7,0.1,1,0.3C9,6.3,9.2,6.6,9.4,7c0.1,0.4,0.2,0.8,0.1,1.1C9.4,8.5,9.2,8.9,9,9.2
	C8.7,9.4,8.4,9.6,8.1,9.7c-0.3,0.1-0.7,0-1-0.1C6.8,9.4,6.5,9.2,6.3,8.9C6.1,8.5,6,8.1,6,7.8c0-0.5,0.2-1,0.5-1.4
	C6.8,6,7.3,5.8,7.7,5.8z M13,5.7c0.3,0,0.7,0.1,1,0.3c0.3,0.2,0.5,0.5,0.6,0.9c0.1,0.4,0.2,0.8,0.1,1.1c-0.1,0.4-0.2,0.7-0.5,1
	c-0.2,0.3-0.6,0.5-0.9,0.5c-0.3,0.1-0.7,0-1-0.1c-0.3-0.1-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.5,0.2-1,0.5-1.4
	C12.1,6,12.5,5.8,13,5.7z"/>
        </svg>
    )
};
export default TripleDot;


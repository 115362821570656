import { createAction } from 'redux-actions';

//Constants
import { NotificationConstants } from './notificationConstants';

export const fetchNotificationList = createAction(NotificationConstants.FETCH_NOTIFICATION_LIST);
export const removeAllNotification = createAction(NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST);
export const readAllNotification = createAction(NotificationConstants.READ_ALL_NOTIFICATION_LIST);
export const checkAllNotification = createAction(NotificationConstants.CHECK_NOTIFICATION);
export const checkAccountNotification = createAction(NotificationConstants.CHECK_ACCOUNT_NOTIFICATION);

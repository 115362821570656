import React, { useEffect, createContext, useState } from 'react';

import {getAuthToken, getFromStorage} from "../utils/common";

export const authContext = createContext({});

const AuthProvider = ({ children, callNotificationApi, setCallNotificationApi }) => {
    const [auth, setAuth] = useState('');

    useEffect(() => {
        setAuthData(getAuthToken());
    }, []);

    const setAuthData = (data) => {
        setAuth(data);
    };

    return (
        <authContext.Provider value={{ auth, setAuthData, setCallNotificationApi, callNotificationApi, userId: getFromStorage('_id') }}>
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;

const keyMirror = require("keymirror");

export const PlatformConstants = keyMirror({
    FETCH_PLATFORM_LIST: undefined,
    CREATE_PLATFORM: undefined,
    EDIT_PLATFORM: undefined,
    FETCH_PLATFORM_BY_ID: undefined,
    PLATFORM_TRASH_LIST: undefined,
    ADD_PLATFORM_TO_TRASH: undefined,
    DELETE_RESTORE_TRASH_PLATFORM: undefined,
});

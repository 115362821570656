import React from 'react'
import "./GreenButton.scss"

const GreenButton = props => {
    const {onClick, className, id, disabled, style, bg} = props;
    return (
        <button type="button" id={id} style={style}
                className={`btn ripple-button GreenButton ${disabled ? "disabled-button" : ""} ${bg ? bg : 'bg-green-button'} ${className || ""}`}
                onClick={onClick} disabled={disabled}>{props.children}</button>
    )
};

export default GreenButton

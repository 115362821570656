import React from 'react';
import {Col, Row} from "antd";
import cameraIcon from "../../assets/icons/Camera.svg";
import TextField from "../TextField";
import {validateLink} from "../../utils/common";
import "./UploadImageButton.scss";

const UploadImageButton = props => {
    const {onChange, label, required, image, showUploadIcon, uploadImgClass} = props;
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput?.current?.click();
    };

    return (
        <>
            <Row className={`${uploadImgClass} border-1-grey border-radius-8`}>
                <Col className="p-0" lg={6} md={6} xs={8}>
                    <div className="uploadImageButton w-100 pl-2">
                        <label className="btn fs-13 btn-default text-center uploadImageBox bg-green-button mb-0 w-100"
                               onClick={() => handleClick()}>
                            {label && <label className="labelClass fs-13 margin-left-5">{label}{required &&
                                <span className="color-red">*</span>}</label>}
                            <div className="button-div fs-13 mt-0 d-flex align-center text-white justify-content-center gap-2">
                                {showUploadIcon && <img src={cameraIcon} alt="image"/>}
                                <span className="inputClassLabel">Upload</span>
                            </div>
                            <input id="file" type="file" onChange={(e) => onChange(e)} hidden/>
                        </label>
                    </div>
                </Col>
                <Col lg={2} md={2} xs={2} className='d-flex fs-13 align-items-center justify-content-center'>
                    <div>
                        OR
                    </div>
                </Col>
                <Col className="p-0 input-class" lg={16} md={16} xs={14}>
                    <TextField type='url' mb0='mb-0' mt0='mt-0' value={image}
                               placeholder='image URL'
                               parentClass='w-100'
                               labelClass='mb-0 fs-13'
                               onChange={(e) => onChange(e, true)}/>
                </Col>
            </Row>
        </>
    )
};

export default UploadImageButton;

import { createAction } from 'redux-actions';
//
import { PlatformConstants } from './platformConstants';

export const fetchPlatformList = createAction(PlatformConstants.FETCH_PLATFORM_LIST);
export const fetchPlatformById = createAction(PlatformConstants.FETCH_PLATFORM_BY_ID);
export const createPlatform = createAction(PlatformConstants.CREATE_PLATFORM);
export const editPlatform = createAction(PlatformConstants.EDIT_PLATFORM);
export const fetchPlatformTrash = createAction(PlatformConstants.PLATFORM_TRASH_LIST);
export const addPlatformToTrash = createAction(PlatformConstants.ADD_PLATFORM_TO_TRASH);
export const addRemovePlatformFromTrash = createAction(PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM);

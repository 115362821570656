const keyMirror = require("keymirror");

export const DashboardConstants = keyMirror({
  FETCH_ACCOUNT_LIST: undefined,
  GET_ACCOUNT_TRASH_LIST: undefined,
  FETCH_ACCOUNT_BY_ID: undefined,
  FETCH_APP_LIST_BY_ID_ACCOUNT: undefined,
  CREATE_ACCOUNT: undefined,
  EDIT_ACCOUNT: undefined,
  DELETE_ACCOUNT: undefined,
  DELETE_RESTORE_TRASH_ACCOUNT: undefined,
  DELETE_RESTORE_FROM_TRASH_ACCOUNT: undefined,
});

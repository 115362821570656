const keyMirror = require("keymirror");

export const ConsoleConstants = keyMirror({
    FETCH_CONSOLE_LIST: undefined,
    FETCH_CONSOLE_BY_ID: undefined,
    DELETE_CONSOLE: undefined,
    CREATE_CONSOLE: undefined,
    EDIT_CONSOLE: undefined,
    CHECK_CONSOLE_BY_ID: undefined,
    LOGIN_TO_CONSOLE: undefined,
    UPDATE_CONSOLE_PASSWORD: undefined,
});

import { createAction } from 'redux-actions';
//
import { DashboardConstants } from './dashboardConstants';

export const fetchAccountList = createAction(DashboardConstants.FETCH_ACCOUNT_LIST);
export const fetchAccountTrashList = createAction(DashboardConstants.GET_ACCOUNT_TRASH_LIST);
export const fetchAccountByID = createAction(DashboardConstants.FETCH_ACCOUNT_BY_ID);
export const fetchAppListByAccountID = createAction(DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT);
export const createAccount = createAction(DashboardConstants.CREATE_ACCOUNT);
export const editAccount = createAction(DashboardConstants.EDIT_ACCOUNT);
export const deleteAccount = createAction(DashboardConstants.DELETE_ACCOUNT);
export const deleteAccountFromTrash = createAction(DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT);
export const doMultiTaskTrashAccount = createAction(DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT);

import { createAction } from 'redux-actions';
//
import { AllAppsConstants } from './allAppsConstants';

export const fetchAppList = createAction(AllAppsConstants.FETCH_APP_LIST);
export const fetchAppListByPagination = createAction(AllAppsConstants.FETCH_APP_LIST_BY_PAGE);
export const fetchAppById = createAction(AllAppsConstants.FETCH_APP_LIST_BY_ID);
export const fetchAppFromTrash = createAction(AllAppsConstants.FETCH_APP_FROM_TRASH);
export const createApps = createAction(AllAppsConstants.CREATE_APP);
export const editApps = createAction(AllAppsConstants.EDIT_APP);
export const editAppsStatus = createAction(AllAppsConstants.EDIT_APP_STATUS);
export const deleteApps = createAction(AllAppsConstants.DELETE_APP);
export const deleteAppsRestore = createAction(AllAppsConstants.DELETE_RESTORE_TRASH_APP);
export const fetchAppByAccount = createAction(AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT);
export const fetchParameterByAppAccount = createAction(AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT);
export const doMultiTasking = createAction(AllAppsConstants.DO_MUTLTI_TASKING);
export const fetchChartData = createAction(AllAppsConstants.FETCH_CHART_DATA);
export const fetchLocationAppData = createAction(AllAppsConstants.FETCH_LOCATION_DATA);
export const doMultiTaskTrashData = createAction(AllAppsConstants.DO_MULTI_TASKING_TRASH);
export const fetchAllAppsChartData = createAction(AllAppsConstants.FETCH_ALL_APP_CHART);
export const fetchAllAppsChartDataByFilter = createAction(AllAppsConstants.FETCH_ALL_APP_CHART_FILTER);
export const fetchAppsChartData = createAction(AllAppsConstants.FETCH_ALL_CHART_APP);
export const fetchAccountChartData = createAction(AllAppsConstants.FETCH_ALL_CHART_ACCOUNT);
export const checkPackageName = createAction(AllAppsConstants.CHECK_PACKAGE_NAME);
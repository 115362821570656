import React from "react";
import {Divider, Modal} from "antd";
import LightBlueButton from "../LightBlueButton";
import GreenButton from "../GreenButton";
import './Modal.scss';

const CustomModal = (props) => {
    const {
        title,
        onClickReset,
        resetBg,
        backBg,
        submitBg,
        disableResetBtn,
        disableCloseBtn,
        resetBtnTitle,
        closeButton,
        visible,
        zIndex,
        errorMsg,
        onClose,
        className,
        children,
        disableBtn,
        btnname,
        centered,
        hideCLoseBtn,
        backBtnName,
        loading,
        onClickSubmit,
        hideDivider,
        hideFooter,
        keyboard = false,
        bodyStyle
    } = props;

    return (
        <Modal
            zIndex={zIndex || 23000}
            centered={centered}
            open={visible}
            onCancel={() => {
                if (!loading && onClose) {
                    onClose()
                }
            }}
            keyboard={keyboard}
            closable={closeButton}
            footer={null}
            bodyStyle={bodyStyle}
            className={`custom-modal ${className || ""}`}
            title={title}
        >
            {!hideDivider && <Divider className='m-0'/>}
            {children}
            {!hideDivider && <Divider/>}
            {!hideFooter && <div className="md-footer mt-3">
                {errorMsg && <p className='color-red'>{errorMsg}</p>}
                <div className="text-right d-flex gap-2 align-items-center justify-content-end mt-4 mb-3">
                    {(!hideCLoseBtn && onClose) && <LightBlueButton bg={backBg} disabled={disableCloseBtn}
                                                                    className='cancel-btn m-0 fs-14 py-2 px-3'
                                                                    onClick={onClose}>{backBtnName ? backBtnName : "Cancel"}</LightBlueButton>}
                    {onClickSubmit &&
                    <GreenButton bg={submitBg} disabled={disableBtn} onClick={onClickSubmit}
                                 className='submit-btn fs-14 py-2 m-0 px-3'>{`${btnname ? btnname : "Submit"}`}</GreenButton>}
                    {onClickReset &&
                    <GreenButton disabled={disableResetBtn} onClick={onClickReset} bg={resetBg || 'bg-orangered'}
                                 className='submit-btn fs-14 py-2 m-0 px-3 ml-3'>{`${resetBtnTitle ? resetBtnTitle : "Reset"}`}</GreenButton>}
                </div>
            </div>}
        </Modal>
    )
};

export default CustomModal;
